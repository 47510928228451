let itemsArray = [
    {
        id: 1,
        type: "bedding set",
        kingSize: true,
        queenSize: true,
        color: "white",
        queensPrice: 249,
        kingsPrice: 299,
        img1: "white-1.jpg",
        img2: "white-2.jpg",
        img3: "white-3.jpg",
        img4: "white-4.jpg",
        popularity: 17,
        inStock: true,
    },
    {
        id: 2,
        type: "bedding set",
        kingSize: true,
        queenSize: true,
        color: "silver gray",
        queensPrice: 249,
        kingsPrice: 299,
        img1: "silver-gray-1.jpg",
        img2: "silver-gray-2.jpg",
        img3: "silver-gray-3.jpg",
        img4: "silver-gray-4.jpg",
        popularity: 14,
        inStock: true,
    },
    {
        id: 3,
        type: "bedding set",
        kingSize: true,
        queenSize: true,
        color: "light green",
        queensPrice: 249,
        kingsPrice: 299,
        img1: "light-green-1.jpg",
        img2: "light-green-2.jpg",
        img3: "light-green-3.jpg",
        img4: "light-green-4.jpg",
        popularity: 11,
        inStock: true,
    },
    {
        id: 4,
        type: "bedding set",
        kingSize: true,
        queenSize: true,
        color: "light pink",
        queensPrice: 249,
        kingsPrice: 299,
        img1: "light-pink-1.jpg",
        img2: "light-pink-2.jpg",
        img3: "light-pink-3.jpg",
        img4: "light-pink-4.jpg",
        popularity: 16,
        inStock: true,
    },
    {
        id: 5,
        type: "bedding set",
        kingSize: true,
        queenSize: true,
        color: "shell",
        queensPrice: 249,
        kingsPrice: 299,
        img1: "shell-1.jpg",
        img2: "shell-2.jpg",
        img3: "shell-3.jpg",
        img4: "shell-4.jpg",
        popularity: 13,
        inStock: true,
    },
    {
        id: 6,
        type: "bedding set",
        kingSize: true,
        queenSize: true,
        color: "skin color",
        queensPrice: 249,
        kingsPrice: 299,
        img1: "skin-color-1.jpg",
        img2: "skin-color-2.jpg",
        img3: "skin-color-3.jpg",
        img4: "skin-color-4.jpg",
        popularity: 10,
        inStock: true,
    },
    {
        id: 7,
        type: "bedding set",
        kingSize: true,
        queenSize: true,
        color: "young pink",
        queensPrice: 249,
        kingsPrice: 299,
        img1: "young-pink-1.jpg",
        img2: "young-pink-2.jpg",
        img3: "young-pink-3.jpg",
        img4: "young-pink-4.jpg",
        popularity: 15,
        inStock: true,
    },
    {
        id: 8,
        type: "bedding set",
        kingSize: true,
        queenSize: true,
        color: "forest",
        queensPrice: 249,
        kingsPrice: 299,
        img1: "forest-1.jpg",
        img2: "forest-2.jpg",
        img3: "forest-3.jpg",
        img4: "forest-4.jpg",
        popularity: 12,
        inStock: true,
    },
    {
        id: 9,
        type: "bedding set",
        kingSize: true,
        queenSize: true,
        color: "rose pink",
        queensPrice: 249,
        kingsPrice: 299,
        img1: "rose-pink-1.jpg",
        img2: "rose-pink-2.jpg",
        img3: "rose-pink-3.jpg",
        img4: "rose-pink-4.jpg",
        popularity: 9,
        inStock: true,
    },
    {
        id: 10,
        type: "bedding set",
        kingSize: true,
        queenSize: true,
        color: "young blue",
        queensPrice: 249,
        kingsPrice: 299,
        img1: "young-blue-1.jpg",
        img2: "young-blue-2.jpg",
        img3: "young-blue-3.jpg",
        img4: "young-blue-4.jpg",
        popularity: 8,
        inStock: true,
    },
    {
        id: 11,
        type: "bedding set",
        kingSize: true,
        queenSize: true,
        color: "sage",
        queensPrice: 249,
        kingsPrice: 299,
        img1: "sage-1.jpg",
        img2: "sage-2.jpg",
        img3: "sage-3.jpg",
        img4: "sage-4.jpg",
        popularity: 6,
        inStock: true,
    },
    {
        id: 12,
        type: "bedding set",
        kingSize: true,
        queenSize: true,
        color: "orange",
        queensPrice: 249,
        kingsPrice: 299,
        img1: "orange-1.jpg",
        img2: "orange-2.jpg",
        img3: "orange-3.jpg",
        img4: "orange-4.jpg",
        popularity: 3,
        inStock: true,
    },
    {
        id: 13,
        type: "bedding set",
        kingSize: true,
        queenSize: true,
        color: "earth brown",
        queensPrice: 249,
        kingsPrice: 299,
        img1: "earh-brown-1.jpg",
        img2: "earh-brown-2.jpg",
        img3: "earh-brown-3.jpg",
        img4: "earh-brown-4.jpg",
        popularity: 8,
        inStock: true,
    },
    {
        id: 14,
        type: "bedding set",
        kingSize: true,
        queenSize: true,
        color: "charming blue",
        queensPrice: 249,
        kingsPrice: 299,
        img1: "charming-blue-1.jpg",
        img2: "charming-blue-2.jpg",
        img3: "charming-blue-3.jpg",
        img4: "charming-blue-4.jpg",
        popularity: 5,
        inStock: true,
    },
    {
        id: 15,
        type: "bedding set",
        kingSize: true,
        queenSize: true,
        color: "steel gray",
        queensPrice: 249,
        kingsPrice: 299,
        img1: "steel-gray-1.jpg",
        img2: "steel-gray-2.jpg",
        img3: "steel-gray-3.jpg",
        img4: "steel-gray-4.jpg",
        popularity: 2,
        inStock: true,
    },
    {
        id: 16,
        type: "bedding set",
        kingSize: true,
        queenSize: true,
        color: "dark gray",
        queensPrice: 249,
        kingsPrice: 299,
        img1: "dark-gray-1.jpg",
        img2: "dark-gray-2.jpg",
        img3: "dark-gray-3.jpg",
        img4: "dark-gray-4.jpg",
        popularity: 7,
        inStock: true,
    },
    {
        id: 17,
        type: "bedding set",
        kingSize: true,
        queenSize: true,
        color: "charcoal gray",
        queensPrice: 249,
        kingsPrice: 299,
        img1: "charcoal-gray-1.jpg",
        img2: "charcoal-gray-2.jpg",
        img3: "charcoal-gray-3.jpg",
        img4: "charcoal-gray-4.jpg",
        popularity: 4,
        inStock: true,
    },
    {
        id: 18,
        type: "bedding set",
        kingSize: true,
        queenSize: true,
        color: "gray stripe",
        queensPrice: 249,
        kingsPrice: 299,
        img1: "gray-stripe-1.jpg",
        img2: "gray-stripe-2.jpg",
        img3: "gray-stripe-3.jpg",
        img4: "gray-stripe-4.jpg",
        popularity: 1,
        inStock: true,
    },
];

export default itemsArray;
