import React from "react";
import { useSpring } from "@react-spring/web";

const QUERY = "(prefers-reduced-motion: no-preference)";
const isRenderingOnServer = typeof window === "undefined";
const getInitialState = () => {
    return isRenderingOnServer ? true : !window.matchMedia(QUERY).matches;
};
function usePrefersReducedMotion() {
    const [prefersReducedMotion, setPrefersReducedMotion] =
        React.useState(getInitialState);
    React.useEffect(() => {
        const mediaQueryList = window.matchMedia(QUERY);
        /* @ts-ignore*/
        const listener = (event) => {
            setPrefersReducedMotion(!event.matches);
        };
        if (mediaQueryList.addEventListener) {
            mediaQueryList.addEventListener("change", listener);
        } else {
            mediaQueryList.addListener(listener);
        }
        return () => {
            if (mediaQueryList.removeEventListener) {
                mediaQueryList.removeEventListener("change", listener);
            } else {
                mediaQueryList.removeListener(listener);
            }
        };
    }, []);
    return prefersReducedMotion;
}

function useBoop({
    x = 0,
    y = 0,
    rotation = 0,
    scale = 1,
    timing = 150,
    springConfig = {
        tension: 300,
        friction: 10,
    },
}) {
    const prefersReducedMotion = usePrefersReducedMotion();
    const [isBooped, setIsBooped] = React.useState(false);
    const style = useSpring({
        transform: isBooped
            ? `translate(${x}px, ${y}px)
         rotate(${rotation}deg)
         scale(${scale})`
            : `translate(0px, 0px)
         rotate(0deg)
         scale(1)`,
        config: springConfig,
    });
    React.useEffect(() => {
        if (!isBooped) {
            return;
        }
        const timeoutId = window.setTimeout(() => {
            setIsBooped(false);
        }, timing);
        return () => {
            window.clearTimeout(timeoutId);
        };
        // eslint-disable-next-line
    }, [isBooped]);
    const trigger = React.useCallback(() => {
        setIsBooped(true);
    }, []);
    let appliedStyle = prefersReducedMotion ? {} : style;
    return [appliedStyle, trigger];
}
export default useBoop;
